import { css } from '@emotion/react'
import React from 'react'
import { rem } from '../../styles/mixin.styles'
import { colors, space } from '../../styles/variables.styles'

type Props = {
  faqCategories: {
    title: string
    items: {
      title: string
      body: string
    }[]
  }[]
  style?: 'primary' | 'secondary'
}

/**
 * FaqItems
 *
 * @returns Faq items contents.
 */
export const FaqItems = ({ faqCategories, style = 'primary' }: Props) => {
  return (
    <>
      {faqCategories.map((category, index) => (
        <div key={index} css={[faqStyles, style === 'primary' ? primaryStyle : secondaryStyle]}>
          {category.title !== '' && <h2>{category.title}</h2>}
          <dl className="faq">
            {category.items.map((item, index) => (
              <div key={index} className="faq__item">
                <dt className="faq__question">{item.title}</dt>
                <dd className="faq__answer" dangerouslySetInnerHTML={{ __html: item.body }} />
              </div>
            ))}
          </dl>
        </div>
      ))}
    </>
  )
}

const faqStyles = css`
  .faq {
    .faq__item {
      display: flex;
      flex-direction: column;
      &:not(:first-of-type) {
        margin-top: clamp(${space.xxl}, 5.2vw, calc(${space.lg} * 2));
      }
      .faq__question,
      .faq__answer {
        display: flex;
        line-height: 1.8;
        gap: calc(${space.md} / 2);
        &::before {
          font-family: var(--roman-font-family);
          font-size: ${rem(20)};
          font-weight: 700;
          display: flex;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }
        ul {
          margin-left: 1em;
        }
      }
      .faq__question {
        font-weight: 700;
        &::before {
          content: 'Q';
        }
      }
      .faq__answer {
        &::before {
          content: 'A';
        }
      }
    }
  }
`

const primaryStyle = css`
  .faq {
    .faq__item {
      gap: ${rem(15)};
    }
    .faq__question,
    .faq__answer {
      &::before {
        width: calc(${space.md} * 2);
        height: calc(${space.md} * 2);
        min-width: calc(${space.md} * 2);
        margin-top: ${rem(-5)};
      }
    }
    .faq__question {
      &::before {
        color: ${colors.white.hex};
        background-color: ${colors.mainBlue.hex};
      }
    }
    .faq__answer {
      &::before {
        border: ${rem(1)} solid ${colors.grayBorder.hex};
        color: rgba(${colors.body.rgb}, 0.7);
        background-color: ${colors.white.hex};
      }
    }
  }
`

const secondaryStyle = css`
  .faq {
    .faq__item {
      gap: clamp(${rem(11)}, 3vw, ${rem(20)});
      background-color: ${colors.white.hex};
      border-radius: ${rem(16)};
      padding: clamp(${rem(12)}, 3vw, ${rem(30)}) clamp(${rem(16)}, 3vw, ${rem(49)}) clamp(${rem(12)}, 3vw, ${rem(30)})
        clamp(${rem(12)}, 3vw, ${rem(30)});
    }
    .faq__question,
    .faq__answer {
      &::before {
        width: calc(${space.sm} * 2);
        height: calc(${space.sm} * 2);
        min-width: calc(${space.sm} * 2);
      }
    }
    .faq__question {
      &::before {
        color: ${colors.white.hex};
        background-color: ${colors.reMainBlue.hex};
      }
    }
    .faq__answer {
      &::before {
        border: ${rem(1)} solid ${colors.reMainBlue.hex};
        color: ${colors.reMainBlue.hex};
        background-color: ${colors.white.hex};
      }
    }
  }
`
